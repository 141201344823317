<template>
    <div>
        <b-overlay :show="loading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                        <b-row>
                            <!-- category name en -->
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Category Name(En)" vid="name_en" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="name_en">
                                    <template v-slot:label>
                                    {{ $t('globalTrans.name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="name_en"
                                    v-model="data.name_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- category name bn -->
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Category Name(Bn)" vid="name_bn" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="name_bn">
                                    <template v-slot:label>
                                    {{ $t('globalTrans.name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="name_bn"
                                    v-model="data.name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- category_code -->
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider name="Category Code" vid="category_code" rules="required">
                                <b-form-group
                                    slot-scope="{ valid, errors }"
                                    label-for="category_code">
                                    <template v-slot:label>
                                    {{ $t('national_award.category_code') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    :readonly="!id ? false : true"
                                    id="category_code"
                                    v-model="data.category_code"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-form>
            </ValidationObserver>
                <hr>
                    <ValidationObserver ref="add_more_data" v-slot="{ handaleAddMoreData, reset }">
                        <b-form @submit.prevent="handaleAddMoreData(addMore)" @reset.prevent="reset">
                            <b-row>
                                    <!-- category details start here-->
                                    <div>
                                        <div>
                                            <p class="card-title" style="width: 100%"> {{ $t('national_award.category_marks_distribution') }}</p>
                                        </div>
                                        <!-- add more -->
                                        <b-row>
                                            <!-- criteria en -->
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Criteria (En)" vid="title_en" rules="required" >
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="title_en">
                                                    <template v-slot:label>
                                                    {{ $t('national_award.criteria') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="title_en"
                                                    v-model="addMoreData.title_en"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- criteria bn -->
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Criteria (Bn)" vid="title_bn" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="title_bn">
                                                    <template v-slot:label>
                                                    {{ $t('national_award.criteria') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="title_bn"
                                                    v-model="addMoreData.title_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- standard value -->
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Standrad Value" vid="standard_value" :rules="{required:true, min_value: 1}">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="standard_value">
                                                    <template v-slot:label>
                                                    {{ $t('national_award.standard_value') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    @keypress="isNumber"
                                                    id="standard_value"
                                                    v-model.number="addMoreData.standard_value"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- mark -->
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Mark" vid="mark" :rules="{required:true, min_value: 1}" >
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="mark">
                                                    <template v-slot:label>
                                                    {{ $t('national_award.mark') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    @keypress="isNumber"
                                                    id="mark"
                                                    v-model.number="addMoreData.mark"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    <div class="d-flex justify-content-end">
                                        <b-button type="button" v-if="is_edit" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                                        <b-button type="button" @click="addMore" variant="success" class="mr-2 btn-sm">{{ is_edit ? $t('globalTrans.update') : $t('globalTrans.add_more') }}</b-button>
                                    </div>
                                    <hr>
                                    <!-- table data show -->
                                    <b-overlay :show="addMoreLoading">
                                    <b-table-simple style="width: 100%" bordered>
                                        <b-tr>
                                            <b-th class="text-center" style="width: 50%;"> {{ $t('national_award.criteria') }}</b-th>
                                            <b-th class="text-center" style="width: 15%;"> {{ $t('national_award.standard_value') }}</b-th>
                                            <b-th class="text-center" style="width: 15%;"> {{ $t('national_award.mark') }}</b-th>
                                            <b-th class="text-center" style="width: 20%;"> {{ $t('globalTrans.action') }}</b-th>
                                        </b-tr>
                                        <template v-if="data.details.length">
                                            <slot v-for="(item, index) in data.details">
                                                <b-tr :key="index">
                                                    <b-td class="text-center">
                                                        {{ currentLocale === 'en' ? item.title_en : item.title_bn }}
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        {{ $n(item.standard_value, {useGrouping: false}) }}
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        {{ $n(item.mark, {useGrouping: false}) }}
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-button @click="editAddMore(index, item)" class="btn btn-sm btn-info"><i class="ri-edit-box-line"></i></b-button>
                                                        <b-button type="button" @click="removeAddMore(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                    </b-td>
                                                </b-tr>
                                            </slot>
                                            <b-tr>
                                                <b-td colspan="2" class="text-right">{{$t('globalTrans.total')}}</b-td>
                                                <b-td class="text-center">{{ $n(totalMark) }}</b-td>
                                            </b-tr>
                                        </template>
                                        <template v-else>
                                            <b-tr>
                                                <b-td colspan="4" class="text-center">{{ $t('national_award.no_data_added') }}</b-td>
                                            </b-tr>
                                        </template>
                                    </b-table-simple>
                                    </b-overlay>
                                    <small v-if="data.details.length && mark_validation" class="py-2 text-danger"><b>Note: </b>{{$t('national_award.total_mark_note_msg')}}</small>
                                    </div>
                                    <!--  category details end here-->
                            </b-row>
                        </b-form>
                    </ValidationObserver>
            <b-row class="text-right">
                <b-col>
                    <b-button type="submit" @click="saveUpdate" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{
                    $t('globalTrans.cancel') }}</b-button>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { awardCategoryStoreApi, awardCategoryUpdateApi } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
import { helpers } from '@/utils/helper-functions'
export default {
    mixins: [teaGardenService],
    name: 'Form',
    props: ['id'],
    data () {
        return {
            valid: null,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            errors: [],
            data: {
                name_en: '',
                name_bn: '',
                details: []
            },
            addMoreLoading: false,
            mark_validation: false,
            addMoreData: {
                title_en: '',
                title_bn: '',
                mark: 0,
                standard_value: 0
            },
            is_edit: false

        }
    },
    created () {
        if (this.id) {
            const tmp = this.getData()
            this.data = tmp
        }
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        },
        totalMark () {
            let total = 0
            this.data.details.forEach(item => {
                total += item.mark
            })
            return total
        }
    },
    methods: {
        isNumber (evt) {
            helpers.isNumber(evt)
        },
        async saveUpdate () {
            // mark can not less or gether 100
            if (this.data.details.length > 0 && this.totalMark !== 100) {
                this.mark_validation = true
                return false
            }
            var check = await this.$refs.form.validate()
            if (check) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                let result = null
                const loadingState = { loading: false, listReload: false }
                if (this.id) {
                    result = await RestApi.putData(teaGardenServiceBaseUrl, `${awardCategoryUpdateApi}/${this.id}`, this.data)
                } else {
                    result = await RestApi.postData(teaGardenServiceBaseUrl, awardCategoryStoreApi, this.data)
                }

                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)

                if (result.success) {
                    this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })

                    this.$bvModal.hide('modal-form')
                } else {
                    this.$refs.form.setErrors(result.errors)
                }
            }
        },
        getData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async addMore () {
            this.addMoreLoading = true
            var check = await this.$refs.add_more_data.validate()
            if (check) {
                if (this.is_edit) {
                    this.data.details.splice(this.addMoreData.index, 1, this.addMoreData)
                } else {
                    this.data.details.push(this.addMoreData)
                }
                this.addMoreData = {}
                this.$refs.add_more_data.reset()
            }
            this.is_edit = false
            this.markValidation()

            setTimeout(() => {
                this.addMoreLoading = false
            }, 100)
        },
        removeAddMore (index) {
            this.data.details.splice(index, 1)
            this.markValidation()
        },
        editAddMore (index, item) {
            this.addMoreData = Object.assign({}, item)
            this.is_edit = true
            this.addMoreData.index = index
        },
        cancelItem () {
          this.addMoreData = {}
          this.is_edit = false
          this.$refs.add_more_data.reset()
          this.markValidation()
        },
        markValidation () {
            if (this.data.details.length > 0 && this.totalMark !== 100) {
                this.mark_validation = true
            } else {
                this.mark_validation = false
            }
        }
    }
}
</script>
